<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-10-22 16:27:04
 * @LastEditors: 郭长生
 * @LastEditTime: 2024-05-30 17:15:38
-->
<template>
    <div>
        <div class="topBox">
            <el-breadcrumb separator="/" class="breadcrumb">
                <el-breadcrumb-item>{{ $t('user.title') }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="contentBox">
            <div v-if="ishasDate">
                <div :style="{ textAlign: 'right', marginBottom: '10px' }">
                    <el-button type="primary" @click="btnAddUser" @keyup.space.prevent.native @keydown.enter.prevent.native>{{ $t('user.addUser') }}</el-button>
                </div>
                <el-form ref="searchForm" :model="tableForm" :inline="true">
                    <el-form-item prop="userName" label-position="left" :label="`${$t('user.name_or_email')}：`">
                        <el-input v-model="tableForm.userName" :placeholder="$t('user.name_or_email_placeholder')" style="width:300px" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="`${$t('user.jurisdiction')}：`" label-width="80px">
                        <el-select v-model="tableForm.authority" :placeholder="$t('system.choice')" clearable @change="valuechange">
                            <el-option v-for="item in establishList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="`${$t('user.userstatus')}：`" label-width="100px">
                        <el-select v-model="tableForm.negative" :placeholder="$t('system.choice')" clearable @change="valuechange">
                            <el-option v-for="item in ofserviceList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :style="{ marginLeft: '25px' }">
                        <el-button type="primary" @keyup.space.prevent.native @keydown.enter.prevent.native @click="searchBtn">
                            {{ $t('system.search') }}
                        </el-button>
                        <el-button @keyup.space.prevent.native @keydown.enter.prevent.native @click="resumebtn">{{ $t('system.reset') }}</el-button>
                    </el-form-item>
                </el-form>

                <el-table :data="tableData" style="width: 100%;">
                    <el-table-column prop="" :label="$t('user.name')">
                        <template #default="{ row }">
                            <span :style="{ color: row.negative == 1 ? 'rgba(0,0,0,0.2)' : '' }">{{ row.username }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" :label="$t('user.email')">
                        <template #default="{ row }">
                            <span :style="{ color: row.negative == 1 ? 'rgba(0,0,0,0.2)' : '' }">{{ row.email }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" :label="$t('user.jurisdiction')">
                        <template #default="{ row }">
                            <span :style="{ color: row.negative == 1 ? 'rgba(0,0,0,0.2)' : '' }">
                                {{ row.authority == 0 ? $t('robot.doorControlnone') : $t('user.createduser') }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" :label="$t('user.remark')">
                        <template #default="{ row }">
                            <span :style="{ color: row.negative == 1 ? 'rgba(0,0,0,0.2)' : '' }">{{ row.remark }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CreatedAt" :label="$t('system.createTime')">
                        <template #default="{ row }">
                            <span :style="{ color: row.negative == 1 ? 'rgba(0,0,0,0.2)' : '' }">
                                {{ moment(row.CreatedAt).format(momentStamp('datetime')) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" :label="$t('system.operation')">
                        <template #default="{ row }">
                            <div v-show="row.negative != 1">
                                <el-button
                                    type="text"
                                    @click="handleEdit(row)"
                                    style="padding: 0 !important"
                                    @keyup.space.prevent.native
                                    @keydown.enter.prevent.native
                                >
                                    {{ $t('global.edit') }}
                                </el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button
                                    type="text"
                                    @click="handleDel(row)"
                                    style="padding: 0 !important"
                                    @keyup.space.prevent.native
                                    @keydown.enter.prevent.native
                                >
                                    {{ $t('system.delete') }}
                                </el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button
                                    type="text"
                                    @click="handleBlockUp(row)"
                                    style="padding: 0 !important"
                                    @keyup.space.prevent.native
                                    @keydown.enter.prevent.native
                                >
                                    {{ $t('user.blockuped') }}
                                </el-button>
                            </div>
                            <div v-show="row.negative == 1">
                                <el-button
                                    type="text"
                                    @click="handleresume(row)"
                                    style="padding: 0 !important"
                                    @keyup.space.prevent.native
                                    @keydown.enter.prevent.native
                                >
                                    {{ $t('user.recover') }}
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    background
                    v-if="total / tableForm.pageSize > 1"
                    :page-size="tableForm.pageSize"
                    :total="total"
                    :current-page="tableForm.page"
                    :page-sizes="[12, 24, 48, 96, 200]"
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    layout="total, prev, pager, next, sizes, jumper"
                    class="pagination"
                />
                <div v-else class="noPage">{{ $t('system.page').replace('XX', total) }}</div>
            </div>
            <div class="nodata" v-else>
                <img :src="totalImg.nodata" />
                <p>{{ $t('user.noUser') }}</p>
                <el-button @click="visible = true" @keyup.space.prevent.native @keydown.enter.prevent.native>{{ $t('region.createNow') }}</el-button>
            </div>
        </div>
        <el-dialog
            :title="isEdit ? $t('user.editUser') : $t('user.addUser')"
            v-model="visible"
            :close-on-press-escape="false"
            custom-class="oneUser"
            @close="cancelDialog"
        >
            <el-radio-group v-if="isEdit ? false : true" v-model="regist_type" style="margin-left:70px;margin-bottom:20px">
                <el-radio :label="1">{{ $t('user.loginEmail') }}</el-radio>
                <el-radio :label="0">{{ $t('user.loginPassword') }}</el-radio>
            </el-radio-group>
            <div class="regist_password" v-if="regist_type == 0" style="padding-left:10px;padding-right:10px;">
                <el-form ref="userFormRef" :rules="userRules" :model="userForm" label-width="150px" style="width: 100%;">
                    <el-form-item prop="username" :label="$t('user.name')">
                        <el-input v-model="userForm.username" :placeholder="$t('system.password.pleaseEnter')" :disabled="isEdit" />
                    </el-form-item>
                    <el-form-item prop="password" :label="$t('login.password')">
                        <el-input v-model="userForm.password" :placeholder="$t('system.password.pleaseEnter')" show-password />
                    </el-form-item>
                    <el-form-item prop="" :label="$t('user.jurisdiction')">
                        <!-- <el-input v-model="userForm.password" :placeholder="$t('system.password.pleaseEnter')" show-password /> -->
                        <el-checkbox v-model="userForm.authority" :label="$t('user.createduser')" size="large" />
                    </el-form-item>
                    <el-form-item prop="remark" :label="$t('system.remark')">
                        <el-input v-model="userForm.remark" :placeholder="$t('system.password.pleaseEnter')" type="textarea" maxlength="140" />
                    </el-form-item>
                </el-form>
                <el-divider></el-divider>
                <div class="tips" v-if="isEdit">
                    <p>{{ $t('user.rule1') }}</p>
                </div>
                <div class="tips" v-else>
                    <p>{{ $t('user.ruleTitle') }}</p>
                    <p>{{ $t('user.rule2') }}</p>
                    <p>{{ $t('user.rule3') }}</p>
                </div>
            </div>
            <div class="regist_email" v-else-if="(regist_type = 1)" style="padding-left:10px;padding-right:10px;">
                <el-form ref="userEmailFormRef" :rules="userEmailRules" :model="userEmailForm" label-width="150px" style="width: 100%;">
                    <el-form-item prop="email" :label="$t('user.email')">
                        <el-input v-model="userEmailForm.email" :placeholder="$t('system.password.pleaseEnter')" :disabled="isEdit" />
                    </el-form-item>
                    <el-form-item prop="password" :label="$t('login.password')">
                        <el-input v-model="userEmailForm.password" :placeholder="$t('system.password.pleaseEnter')" show-password />
                    </el-form-item>
                    <el-form-item prop="" :label="$t('user.jurisdiction')">
                        <!-- <el-input v-model="userForm.password" :placeholder="$t('system.password.pleaseEnter')" show-password /> -->
                        <el-checkbox v-model="userEmailForm.authority" :label="$t('user.createduser')" size="large" />
                    </el-form-item>
                    <el-form-item prop="remark" :label="$t('system.remark')">
                        <el-input v-model="userEmailForm.remark" :placeholder="$t('system.password.pleaseEnter')" type="textarea" maxlength="140" />
                    </el-form-item>
                </el-form>
                <el-divider></el-divider>
                <div class="tips" v-if="isEdit">
                    <p>{{ $t('user.emailrule1') }}</p>
                </div>
                <div class="tips" v-else>
                    <p>{{ $t('user.ruleTitle') }}</p>
                    <p>{{ $t('user.emailrule2') }}</p>
                </div>
                <el-dialog
                    v-model="emailConfirm"
                    width="50%"
                    :show-close="true"
                    :close-on-press-escape="false"
                    :close-on-click-modal="false"
                    @close="cancelEmailConfirm"
                    append-to-body
                >
                    <div class="emailConfirm">
                        <!-- <p class="tip">请输入邮箱收到的验证码，完成注册。若未收到验证码，<span v-if="leftTime>0"><span>{{leftTime}}s </span>后</span>可<el-link :type="leftTime==0 ? 'primary':''" :disabled="leftTime!=0" @click.prevent="reSendEmail">重发</el-link></p> -->
                        <p class="tip">
                            <span v-html="countdownTips"></span>
                            <el-link :type="leftTime == 0 ? 'primary' : 'default'" :disabled="leftTime != 0" @click.prevent="reSendEmail">
                                【{{ $t('user.resend') }}】
                            </el-link>
                        </p>
                        <el-input v-model="emailVerifyCode" @focus="emailVerifyError = ''" />
                        <p style="color:red;margin-top:5px;" v-if="emailVerifyError != ''">{{ emailVerifyError }}</p>
                        <el-button :type="emailVerifyCode == '' ? 'info' : 'primary'" :disabled="emailVerifyCode == ''" @click="verifyEmail">
                            {{ $t('user.complete') }}
                        </el-button>
                    </div>
                </el-dialog>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="cancelDialog" @keyup.space.prevent.native @keydown.enter.prevent.native>{{ $t('system.cancel') }}</el-button>
                    <el-button type="primary" @click="handleSubmit" @keyup.space.prevent.native @keydown.enter.prevent.native>
                        {{ $t('system.determine') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <!-- <el-input :placeholder="$t('system.password.pleaseEnter')" type="textarea" maxlength="140" /> -->
    </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs, unref } from 'vue';
import moment from 'moment';
import { ElForm } from 'element-plus';
import { totalImg, momentStamp } from '@/utils/robot';
import { searchUser, addUser, addEmailUser, delUser, editUser, blockUPuser } from '@/apis/user';
import { ElMessageBox, ElMessage } from 'element-plus';
import i18n from '@/locales/index';
let _t = i18n.global.t;
let reName = /^[a-zA-Z0-9~`·!！，。；‘【】@#$%^&*()_,.?|\-\\\/]+$/;
// let reName = /^[a-zA-Z0-9]+$/;
let rePassword = /^[a-zA-Z0-9 ；，、’‘：？——【】《》“”！!'"_<>`,·。≈{}~～()-￥%……（^）|?,@#$&()|;:"<>*\\/\n]+$/;
let newpassword = /[\u4E00-\u9FA5\u0800-\u4e00\uac00-\ud7ff]/;

export default {
    setup() {
        const regist_type = ref(0);
        const emailConfirm = ref(false);
        const searchForm = ref(ElForm);
        const userFormRef = ref(ElForm);
        const userEmailFormRef = ref(ElForm);
        const emailVerifyCode = ref('');
        const emailVerifyError = ref('');
        const countdownTips = ref('');
        let timer = 0;
        const leftTime = ref(30);
        const validatePass = (rule, value, callback) => {
            if (state.isEdit) {
                if ((value && value.length > 20) || (value && value.length < 6) || value == '') {
                    callback(new Error(_t('user.passwordRule2')));
                } else if (value && !rePassword.test(value)) {
                    callback(new Error(_t('user.passwordRule2')));
                } else if (value && newpassword.test(value)) {
                    callback(new Error(_t('user.passwordRule2')));
                } else {
                    callback();
                }
            } else {
                if (value === '') {
                    callback(new Error(_t('user.passwordRule1')));
                } else if (value.length > 20 || value.length < 6) {
                    callback(new Error(_t('user.passwordRule2')));
                } else if (!rePassword.test(value)) {
                    callback(new Error(_t('user.passwordRule3')));
                } else if (value && newpassword.test(value)) {
                    callback(new Error(_t('user.passwordRule3')));
                } else {
                    callback();
                }
            }
        };
        const validateUser = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(_t('user.usernameRule1')));
            } else if (value.length > 20 || value.length < 6) {
                callback(new Error(_t('user.usernameRule2')));
            } else if (value && newpassword.test(value)) {
                callback(new Error(_t('user.passwordRule3')));
            } else {
                callback();
            }
        };
        const validateEmail = (rule, value, callback) => {
            if (value === '') callback(new Error(_t('user.emailRule1')));
            //else if(newpassword.test(value)) callback(new Error(_t('user.passwordRule3')));
            else if (
                !/^[a-zA-Z0-9\u4e00-\u9fa5\u00C0-\u024F](([_-]|\.)?[a-zA-Z0-9\u4e00-\u9fa5\u00C0-\u024F]+)+@[a-zA-Z0-9]+([_-]?[a-zA-Z0-9]+)?(\.[a-zA-Z0-9]+([_-]?[a-zA-Z0-9]+)?)+$/.test(
                    value
                )
            )
                callback(new Error(_t('user.emailRule2')));
            //else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))  callback(new Error(_t('user.emailRule2')));
            else callback();
        };
        const state = reactive({
            isShow: false,
            momentStamp: momentStamp,
            tableForm: {
                userName: '',
                authority: '',
                negative: '',
                pageSize: 12,
                page: 1
            },
            authoritycheck: false,
            establishList: [
                { value: _t('user.createduser'), key: 'true' },
                { value: _t('robot.doorControlnone'), key: 'false' }
            ],
            ofserviceList: [
                { value: _t('user.normal'), key: 'false' },
                { value: _t('user.blockup'), key: 'true' }
            ],
            total: 0,
            userForm: {
                username: '',
                password: '',
                remark: '',
                authority: false
            },
            totalImg: totalImg(),
            tableData: [],
            isEdit: false,
            visible: false,
            ishasDate: false,
            userRules: {
                username: [{ validator: validateUser, trigger: 'blur', required: true }],
                password: [{ validator: validatePass, trigger: 'blur', required: true }]
            },
            userEmailForm: {
                email: '',
                password: '',
                remark: '',
                authority: false
            },
            userEmailRules: {
                email: [{ validator: validateEmail, trigger: 'blur', required: true }],
                password: [{ validator: validatePass, trigger: 'blur', required: true }]
            }
        });
        const methods = reactive({
            resumebtn() {
                state.tableForm = {
                    userName: '',
                    authority: '',
                    negative: '',
                    pageSize: 12,
                    page: 1
                };
                methods.getList();
            },
            valuechange(val) {
                console.log(val);
                state.tableForm.page = 1;
            },
            searchBtn: () => {
                state.tableForm.page = 1;
                methods.getList();
            },
            getList() {
                searchUser(state.tableForm).then((res) => {
                    if (res.code == 200) {
                        res.data.lists.forEach((item, index) => {
                            if (item.username === 'admin') {
                                res.data.lists.splice(index, 1);
                            }
                        });
                        state.tableData = res.data.lists;
                        state.total = res.data.total;
                        res.data.lists.length > 0 ? (state.ishasDate = true) : null;
                    }
                });
            },
            handleEdit(obj) {
                if (obj.username == '') regist_type.value = 1;
                else regist_type.value = 0;
                state.visible = true;
                state.isEdit = true;
                state.userForm = {
                    username: obj.username,
                    remark: obj.remark,
                    authority: obj.authority == 0 ? false : true,
                    password: ''
                };
                state.userEmailForm = {
                    email: obj.email,
                    remark: obj.remark,
                    authority: obj.authority == 0 ? false : true,
                    password: ''
                };
            },
            handleresume(obj) {
                //'恢复用户'
                console.log('handle恢复用户');
                ElMessageBox.confirm(_t('user.recoveruser'), _t('user.RestoringaUser'), {
                    confirmButtonText: _t('user.recover'),
                    cancelButtonText: _t('system.cancel'),
                    type: 'warning'
                })
                    .then(() => {
                        blockUPuser({ user_name: obj.username, email: obj.email, cmd: '0' }).then((res) => {
                            if (res.code == 200) {
                                ElMessage({
                                    type: 'success',
                                    message: _t('user.Successfulrecovery')
                                });
                                state.tableForm.page = 1;
                                methods.getList();
                            } else {
                                ElMessage({
                                    type: 'error',
                                    message: _t('user.failedrecovery')
                                });
                            }
                        });
                    })
                    .catch(() => {});
            },
            handleBlockUp(obj) {
                //'停用用户'
                console.log('handleBlockUp');
                ElMessageBox.confirm(_t('user.notapplicableuser'), _t('user.DeactivatingaUser'), {
                    confirmButtonText: _t('user.blockup'),
                    cancelButtonText: _t('system.cancel'),
                    type: 'warning'
                })
                    .then(() => {
                        blockUPuser({ user_name: obj.username, email: obj.email, cmd: '1' }).then((res) => {
                            if (res.code == 200) {
                                ElMessage({
                                    type: 'success',
                                    message: _t('user.Deactivatingasuccessful')
                                });
                                state.tableForm.page = 1;
                                methods.getList();
                            } else {
                                ElMessage({
                                    type: 'error',
                                    message: _t('user.Deactivatingafailed')
                                });
                            }
                        });
                    })
                    .catch(() => {});
            },
            handleDel(obj) {
                console.log('handleDel');
                ElMessageBox.confirm(_t('user.delUserConfiem'), _t('user.delUser'), {
                    confirmButtonText: _t('system.delete'),
                    cancelButtonText: _t('system.cancel'),
                    type: 'warning'
                })
                    .then(() => {
                        delUser({ name: obj.username, email: obj.email }).then((res) => {
                            if (res.code == 200) {
                                ElMessage({
                                    type: 'success',
                                    message: _t('system.deleteSuccess')
                                });
                                state.tableForm.page = 1;
                                methods.getList();
                            } else {
                                ElMessage({
                                    type: 'error',
                                    message: _t('global.deleteFail')
                                });
                            }
                        });
                    })
                    .catch(() => {});
            },
            handleSubmit() {
                if (regist_type.value == 0) {
                    unref(userFormRef).validate((valid) => {
                        console.log('valid', valid, state.isEdit);
                        if (valid) {
                            if (state.isEdit) {
                                console.log('edit');
                                state.userForm.username = state.userForm.username.toLowerCase();
                                state.userForm.authority == false ? (state.userForm.authority = 0) : (state.userForm.authority = 1);
                                editUser(state.userForm).then((res) => {
                                    if (res.code == 200) {
                                        ElMessageBox.alert(_t('user.edieUserTips'), _t('global.editSuccess'), {
                                            confirmButtonText: _t('system.determine'),
                                            type: 'success',
                                            dangerouslyUseHTMLString: true
                                        }).then(() => {
                                            state.tableForm.page = 1;
                                            methods.getList();
                                            methods.cancelDialog();
                                        });
                                    } else {
                                        ElMessage({
                                            type: 'error',
                                            message: res.message
                                        });
                                    }
                                });
                            } else {
                                state.userForm.username = state.userForm.username.toLowerCase();
                                state.userForm.authority == false ? (state.userForm.authority = 0) : (state.userForm.authority = 1);
                                addUser(state.userForm).then((res) => {
                                    if (res.code == 200) {
                                        ElMessageBox.alert(_t('user.edieUserTips'), _t('global.createSuccess'), {
                                            confirmButtonText: _t('system.determine'),
                                            type: 'success',
                                            dangerouslyUseHTMLString: true
                                        }).then(() => {
                                            state.tableForm.page = 1;
                                            methods.getList();
                                            methods.cancelDialog();
                                        });
                                    } else if (res.code === 100013) {
                                        ElMessage({
                                            type: 'error',
                                            message: _t('user.repeatUser')
                                        });
                                    } else {
                                        ElMessage({
                                            type: 'error',
                                            message: res.message
                                        });
                                    }
                                });
                            }
                        }
                    });
                } else if (regist_type.value == 1) {
                    unref(userEmailFormRef).validate((valid) => {
                        if (valid) {
                            if (state.isEdit) {
                                // 编辑邮箱用户
                                editUser({
                                    username: '',
                                    email: state.userEmailForm.email,
                                    password: state.userEmailForm.password,
                                    authority: state.userEmailForm.authority == false ? 0 : 1,
                                    remark: state.userEmailForm.remark
                                }).then((res) => {
                                    if (res.code == 200) {
                                        ElMessageBox.alert(_t('user.edieUserTips'), _t('global.editSuccess'), {
                                            confirmButtonText: _t('system.determine'),
                                            type: 'success',
                                            dangerouslyUseHTMLString: true
                                        }).then(() => {
                                            state.tableForm.page = 1;
                                            methods.getList();
                                            methods.cancelDialog();
                                        });
                                    } else {
                                        ElMessage({ type: 'error', message: res.message });
                                    }
                                });
                            } else {
                                addEmailUser({
                                    email: state.userEmailForm.email,
                                    password: state.userEmailForm.password,
                                    authority: state.userEmailForm.authority == false ? 0 : 1,
                                    remark: state.userEmailForm.remark,
                                    code: ''
                                })
                                    .then((res) => {
                                        if (res.code == 200) {
                                            // 需要发送邮件
                                            emailVerifyCode.value = '';
                                            emailConfirm.value = true;
                                            methods.countDown();
                                        } else if (res.code == 400) {
                                            ElMessage({
                                                message: _t('global.error400'),
                                                type: 'error',
                                                duration: 1e3 * 5
                                            });
                                            emailConfirm.value = false;
                                        } else if (res.code == 500) {
                                            ElMessage({
                                                message: _t('global.error500'),
                                                type: 'error',
                                                duration: 1e3 * 5
                                            });
                                            emailConfirm.value = false;
                                        } else if (res.code == 10001) {
                                            ElMessage({
                                                message: _t('user.tipsemailexists'),
                                                type: 'error',
                                                duration: 1e3 * 5
                                            });
                                            emailConfirm.value = false;
                                            //state.userEmailForm.email='';
                                        } else if (res.code == 20004) {
                                            ElMessage({
                                                message: _t('global.error20004'),
                                                type: 'error',
                                                duration: 1e3 * 5
                                            });
                                            emailConfirm.value = false;
                                        } else if (res.code == 40008) {
                                            ElMessage({
                                                message: _t('global.error40008'),
                                                type: 'error',
                                                duration: 1e3 * 5
                                            });
                                            emailConfirm.value = false;
                                        } else if (res.code == 40009) {
                                            ElMessage({
                                                message: _t('global.error40009'),
                                                type: 'error',
                                                duration: 1e3 * 5
                                            });
                                            emailConfirm.value = false;
                                        } else {
                                            ElMessage({
                                                message: _t('user.tipselseerror'),
                                                type: 'error',
                                                duration: 1e3 * 5
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        ElMessage({
                                            message: _t('user.tipsinterneterror'),
                                            type: 'error',
                                            duration: 1e3 * 5
                                        });
                                    });
                            }
                        }
                    });
                }
            },
            cancelDialog() {
                methods.resetForm();
                state.isEdit = false;
                state.visible = false;
            },
            resetForm() {
                switch (regist_type.value) {
                    case 0:
                        const form = unref(userFormRef);
                        form.resetFields();
                        break;
                    case 1:
                        const formEmail = unref(userEmailFormRef);
                        formEmail.resetFields();
                        emailVerifyCode.value = '';
                        break;
                    default:
                        break;
                }
            },
            handleCurrentChange(page) {
                state.tableForm.page = page;
                methods.getList();
            },
            handleSizeChange(val) {
                state.tableForm.pageSize = val;
                state.tableForm.page = 1;
                methods.getList();
            },
            verifyEmail() {
                addEmailUser({
                    email: state.userEmailForm.email,
                    password: state.userEmailForm.password,
                    authority: state.userEmailForm.authority == false ? 0 : 1,
                    remark: state.userEmailForm.remark,
                    code: emailVerifyCode.value
                })
                    .then((res) => {
                        if (res.code == 200) {
                            emailVerifyError.value = '';
                            emailConfirm.value = false;
                            setTimeout(() => {
                                methods.cancelDialog();
                            }, 5);
                            ElMessage({
                                message: _t('user.tipscreateemailsuccess'),
                                type: 'success',
                                duration: 1e3 * 5
                            });
                            state.tableForm.page = 1;
                            methods.getList();
                        } else if (res.code == 400) {
                            emailVerifyError.value = _t('global.error400');
                        } else if (res.code == 500) {
                            emailVerifyError.value = _t('global.error500');
                        } else if (res.code == 10001) {
                            emailVerifyError.value = _t('user.tipsemailexists');
                        } else if (res.code == 20004) {
                            emailVerifyError.value = _t('global.error20004');
                        } else if (res.code == 40008) {
                            emailVerifyError.value = _t('global.error40008');
                        } else if (res.code == 40009) {
                            emailVerifyError.value = _t('global.error40009');
                        } else {
                            emailVerifyError.value = _t('user.tipselseerror');
                        }
                        // if(res.code==200){
                        //   emailVerifyError.value='';
                        //   emailConfirm.value=false;
                        //   setTimeout(() => {
                        //     methods.cancelDialog();
                        //   }, 5);
                        //   ElMessage({
                        //     message: _t('user.tipscreateemailsuccess'),
                        //     type: 'success',
                        //     duration:1e3*5
                        //   });
                        //   state.tableForm.page = 1;
                        //   methods.getList();
                        // }else{
                        //   emailVerifyError.value=_t('user.tipsverifycodeerror');
                        // }
                    })
                    .catch((err) => {
                        emailVerifyError.value = _t('user.tipsverifycodeerror');
                    });
            },
            reSendEmail() {
                emailVerifyCode.value = '';
                methods.countDown();
                addEmailUser({
                    email: state.userEmailForm.email,
                    password: state.userEmailForm.password,
                    authority: state.userEmailForm.authority == false ? 0 : 1,
                    remark: state.userEmailForm.remark,
                    code: ''
                })
                    .then((res) => {
                        ElMessage({
                            message: _t('user.tipsresendemail'),
                            type: 'success',
                            duration: 1e3 * 5
                        });
                    })
                    .catch((err) => {
                        ElMessage({
                            message: _t('user.tipsinterneterror'),
                            type: 'error',
                            duration: 1e3 * 5
                        });
                    });
            },
            countDown() {
                clearInterval(timer);
                leftTime.value = 30;
                timer = setInterval(() => {
                    leftTime.value = leftTime.value - 1;
                    countdownTips.value = _t('user.emailregisttip').replace('%d', `<span class="cd">${leftTime.value}</span>`);
                    if (leftTime.value < 1) {
                        clearInterval(timer);
                    }
                }, 1e3);
            },
            cancelEmailConfirm() {
                methods.resetForm();
                state.isEdit = false;
                state.visible = false;
            },
            btnAddUser() {
                state.isEdit = false;
                state.visible = true;
                state.userForm = {
                    username: '',
                    remark: '',
                    authority: false,
                    password: ''
                };
                state.userEmailForm = {
                    email: '',
                    remakr: '',
                    authority: false,
                    password: ''
                };
            }
        });
        onMounted(() => {
            methods.getList();
            countdownTips.value = _t('user.emailregisttip').replace('%d', `<span class="cd">${leftTime.value}</span>`);
        });
        return {
            ...toRefs(state),
            ...toRefs(methods),
            searchForm,
            userFormRef,
            userEmailFormRef,
            moment,
            regist_type,
            emailConfirm,
            emailVerifyCode,
            emailVerifyError,
            leftTime,
            countdownTips
        };
    }
};
</script>
<style lang="scss">
.contentBox {
    padding: 24px;
    margin: 24px;
    border-radius: 2px;
    background: #fff;

    .nodata {
        background: #fff;
        height: 700px;
        padding: 160px;
        text-align: center;
        margin: 0;
    }
}

.oneUser {
    .tips {
        font-size: 13px;
        color: #afafaf;
        line-height: 14px;
    }
}

.topBox {
    background: #fff;
    position: relative;
}

.noPage {
    text-align: right;
    padding: 46px 24px 28px;
    font-size: 14px;
    color: #606266;
}

.emailConfirm {
    padding: 0 75px;
    text-align: center;

    p {
        margin-bottom: 10px;
        text-align: left;
        clear: both;

        > span > span {
            color: #1890ff;
        }
    }

    .el-input {
        clear: both;
        margin-bottom: 10px;
    }
}

.el-dialog {
    min-width: 690px;
}
</style>
